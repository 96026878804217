.App {
  text-align: center;
  background-color: #141f34;
  height: 100%;
}

.App-header {
  background-color: #141f34;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: 150%;
  color: white;
  padding: 1vh 0 0 3vw;
  font-family: righteous;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: koodak;
  src: url(fonts/koodak.ttf);
}

@font-face {
  font-family: righteous;
  src: url(fonts/Righteous-Regular.ttf);
}

.farsi-theme-font {
  font-family: koodak;
  font-size: 150%;
}

.english-theme-font {
  font-family: righteous;
}


/* .banner {
  height: 20px;
} */

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.grid {
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.keyboard {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 1vh;
}

.keyboard-rows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
}

.enter-del-key {
  cursor: pointer;
  min-height: 50px;
  min-width: 40px;
  text-align: center;
  font-size: 110%;
  font-family: righteous;
  color: black;
  padding: 0;
  margin: 1px;
  border-radius: 7px;
  border: none;
  background-color: lightblue;
}

.instructionsOverlay {
  text-align: left;
}

.startButton {
  cursor: pointer;
  height: 10%;
  width: 50%;
  font-size: 150%;
  background-color: #568203;
  color: aliceblue;
  margin-top: 15px;
  border: hidden;
  border-radius: 0.5rem;
}

.statsCloseButton {
  cursor: pointer;
  height: 20%;
  width: 50%;
  font-size: 150%;
  background-color: #568203;
  color: aliceblue;
  border: hidden;
  border-radius: 0.5rem;
}

.flipCells {
  transform: rotatY(180deg);
}

.menu-icons {
  height: max-content;
  width: max-content;
  position: absolute;
  right: 0px;
}

.menu-icon {
  cursor: pointer;
  height: 50px;
  width: 50px;
  font-size: 150%;
  color: aliceblue;
  background-color: transparent;
  border-color: transparent;
  padding: 1vh 1vw 0 0;
}

.instructionCell {
  color: #000000;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  margin: 5px 2px 5px 2px;
  text-align: center;
  font-size: 250%;
  font-family: koodak;
  backface-visibility: hidden;
  border-radius: 0.5rem;
}

@keyframes instructionCellFlip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.instructionCellParent {
  position: relative;
  transition: transform 2s;
  transform-style: preserve-3d;
  transform: rotateY(180deg); 
  /* animation: 2s ease-in-out 0s 1 instructionCellFlip; */
}

.instructionCellFront {
  position: absolute;
  backface-visibility: hidden;
}
.instructionCellBack {
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.greyCell {
  background-color: darkgray;
}
.greenCell {
  background-color: #568203;
}
.yellowCell {
  background-color: yellow;
  /* animation: 2s ease-in-out 0s 1 instructionCellFlip; */
}


.statsLabelCell {
  font-size: small;
  width: 60px;
  padding: 1px 10px 30px 5px;
  text-align: center;

}
.statsContentCell {
  font-size: 35px;
  width: 60px;
  padding: 1px 5px 1px 5px;
  text-align: center;
}

.countdownTimer {
  font-size: 30px;
  padding-bottom: 10px;
}

.learnFarsiDiv {
  margin: auto;
  margin-top: 2.5vh;
  padding: 3px;
  border: 1px solid white;
  width: fit-content;
}

.learnFarsiHref {
  font-family: righteous;
  font-size: 20px;
  color: white;
  text-decoration: none;
}